import classNames from "classnames"
import { useNavigate } from "react-router-dom"

const BtnFoodSupport = ({
  petId,
  disabled = false,
}: any) => {
  const navigate = useNavigate()

  return (<>
    <button
      className={classNames({
        'flex align-center w-80 h-16 gap-2 py-3 px-4 rounded-xl m-auto bg-blue': true,
        'opacity-70': disabled,
      })}
      onClick={() => navigate(`/pets/${petId}/food-support`)}
      disabled={disabled}
    >
      <figure>
        <img src="/icon-food.png" alt="" className="h-6" />
      </figure>
      <div className="text-left">
        <p className="text-xl font-bold leading-none">ペットフードサポート</p>
      </div>
    </button>
  </>)
}

export default BtnFoodSupport
