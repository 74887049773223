import styled from "styled-components"
import { useApp, useAppDispatch, useAppSelector } from "../../hooks"
import { BackLink, DateSelector, Input, InputPassword, RadioGroup, RegisterStep } from "../../components/common"
import { Link, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { getAppStates, setRegisterCurrentStep, setRegisterData } from "../../store/appSlice"
import { GENDERS } from "../../helpers/enums"

const schema = object().shape({
  name: string().required('お名前を入力してください'),
  sex: string().required('性別を選択してください'),
  birthDate: string().required('生年月日を選択してください'),
});

const RegisterStep2 = () => {
  const dispatch = useAppDispatch()
  const { setUser } = useApp()

  const { registerCurrentStep, registerData } = useAppSelector(getAppStates)

  const navigate = useNavigate()

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      sex: '1',
      birthDate: '',
    }
  })

  useEffect(() => {
    reset({
      name:   registerData.name ?? '',
      sex:    registerData.sex ?? '',
      birthDate: registerData.birthDate ?? '',
    })
  }, [])

  const onNext = (data: any) => {
    dispatch(setRegisterData(data))
    dispatch(setRegisterCurrentStep(Math.max(registerCurrentStep, 3)))
    navigate('/register/step3')
  }

  const onBack = () => {
    navigate('/register/step1')
  }

  return (<>
    <Wrapper>
      <h1 className="title">飼い主登録<span>owner</span></h1>
      <RegisterStep index={2} />
      <h2 className="text-2xl	font-bold mt-12 mb-7">Sign UP</h2>
      <form onSubmit={handleSubmit(onNext)}>
        <div className="mb-8">
          <Input
            label="お名前"
            name="name"
            control={control}
            className="mb-14"
          />
        </div>
        <div className="mb-8">
          <RadioGroup
            label="性別"
            name="sex"
            control={control}
            options={GENDERS}
            dir={'vertical'}
          />
        </div>
        <DateSelector
          label="生年月日"
          name="birthDate"
          control={control}
          minYear={1900}
          defaultYear={1974}
        />
        <div className="mt-10">
          <button
            type="submit"
            className="btn bg-green"
          >次へ</button>
          <button
            className="btn sky-1 mt-4"
            onClick={onBack}
          >戻る</button>
        </div>
      </form>
    </Wrapper>
  </>)
}

export default RegisterStep2

const Wrapper = styled.div`
  h1 {
    padding: 20px 0;
    span {
      top: 28px;
    }
  }
`



