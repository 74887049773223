import { Control, Controller } from "react-hook-form"
import styled from "styled-components"
import { valueToLabel } from "../../helpers/functions"
import ReactSelect, { components } from "react-select"

interface Props {
  label?: string,
  name?: string,
  control?: Control<any>,
  options?: any[],
  readOnly?: boolean,
  controlClassName?: string
  placeholder?: string,
  isMulti?: boolean,
}

const FormSearchSelect = ({
  label,
  name = 'select',
  control,
  options = [],
  readOnly = false,
  controlClassName = '',
  placeholder = '',
  isMulti = false,
}: Props) => {
  return (<>
    <Wrapper className="text-black mb-[14px]">
      {!!label && <label className="inline-block text-sm mb-1 font-bold">{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({field: {name, value, onChange}, fieldState}) => {
          const { error, invalid } = fieldState
          const val = isMulti ? options.filter((option) => (value ?? []).includes(option.value)) : (
            !!value || value === 0 ? options.find((option) => option.value == value) : null
          )
          return <>
            <div>
              {!readOnly ? <>
                <ReactSelect
                  name={name}
                  value={val}
                  onChange={(obj) => !isMulti ? onChange(obj?.value ?? null) : onChange(obj.map((o: any) => o.value))}
                  options={options}
                  isClearable
                  classNamePrefix={'custom-react-select'}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Control: SelectControl,
                  }}
                  placeholder={placeholder}
                  isMulti={isMulti}
                />
              </> : <>
                <input
                  type="text"
                  value={valueToLabel(value, options)}
                  className={`h-10 bg-white text-black px-3.5 ${controlClassName}`}
                  readOnly
                />
              </>}
            </div>
            {invalid && <span className="text-rose-500 font-bold text-sm">{error?.message}</span>}
          </>
        }}
      />
    </Wrapper>
  </>)
}

export default FormSearchSelect

const SelectControl = ({
  children,
  ...props
}: any) => (
  <components.Control {...props}>
    <img src="/icon-search.png" alt="" className="w-8 ml-2" />
    {children}
  </components.Control>
);

const Wrapper = styled.div`
  input:focus-visible {
    outline: none;
  }
  .custom-react-select__control {
    border: none;
    border-radius: 0;
  }
  .custom-react-select__control--is-focused {
    border: none;
  }
  .custom-react-select__input {
    height: 44px;
  }
`
