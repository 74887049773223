import { Link, createSearchParams, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { Footer, LinkGroup, SearchSelect } from "../../components/common"
import { set, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { useEffect, useMemo, useState } from "react"
import { useApp } from "../../hooks"
import { PetfoodService } from "../../api"

const schema = object().shape({
  mid: string().defined().nullable(),
  fid: string().defined().nullable()
    .required('商品を選択してください'),
})

const FoodSupport2 = () => {
  const {id: petId} = useParams()
  const navigate = useNavigate()

  const {setIsLoading} = useApp()
  const [makers, setMakers] = useState<any[]>([])
  const [products, setProducts] = useState<any[]>([])

  useEffect(() => {
    const getMaster = async () => {
      try {
        setIsLoading(true)
        const {data} = await PetfoodService.getMarkerAndProduct({
          id: petId,
        })
        const {status, petfoodMakerAndProduct} = data
        if (status == 'ok') {
          setMakers(petfoodMakerAndProduct.maker)
          setProducts(petfoodMakerAndProduct.product)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    getMaster()
  }, [])

  const {
    watch,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mid: '',
      fid: '',
    }
  })

  const mid = watch('mid')

  const productOptions = useMemo(() => {
    if (!mid) return products
    return products.filter((el) => el.makerId == mid)
  }, [mid, products])

  useEffect(() => {
    setValue('fid', '')
  }, [mid])

  const onSearch = (values: any) => {
    navigate({
      pathname: `/pets/${petId}/foods/search`,
      search: `${createSearchParams({
        fid: values.fid,
      })}`
    })
  }

  return (<>
    <Wrapper>
      <h1>メーカー・商品名から探す</h1>
      <div className="form-group">
        <label className="form-label"><span className="step">STEP1</span>メーカーを選ぶ</label>
        <SearchSelect
          control={control}
          name="mid"
          options={makers}
        />
      </div>
      <div className="form-group">
        <label className="form-label"><span className="step">STEP2</span>商品を選ぶ</label>
        <SearchSelect
          control={control}
          name="fid"
          options={productOptions}
        />
      </div>
      <button
        className="btn-box bg-blue"
        onClick={handleSubmit(onSearch)}
      >結果をみる</button>
      <Footer />
      <LinkGroup petId={petId} className="mt-16" />
    </Wrapper>
  </>)
}

export default FoodSupport2

const Wrapper = styled.div`
  h1 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 24px 0;
    margin: 20px 0 40px;
    background: url(/txt01.png) no-repeat center;
    background-size: auto 70px;
  }
  .form-group {
    position: relative;
    margin-bottom: 70px;
    &:after {
      content: '▼';
      position: absolute;
      color: #282828;
      font-size: 28px;
      line-height: 1;
      top: calc(100% + 18px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .btn-box {
    font-size: 22px;
  }
`
