import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates, setNewPetData } from "../../store/appSlice"
import { useEffect, useMemo } from "react"
import { PET_TYPES } from "../../helpers/enums"
import { valueToLabel } from "../../helpers/functions"
import { useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { array, mixed, object, string } from "yup"
import { SearchSelect } from "../../components/common"

const schema = object().shape({
  petBreedList: array()
    .of(object().shape({
      item: mixed().nullable()
    }))
    .min(1, 'ペット種類を選択してください')
    .test('atLeastOne', 'ペット種類を選択してください', (value) => (value ?? []).some((row: any) => row.item !== null)),
})

const Question4 = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { newPetData, masterData } = useAppSelector(getAppStates)

  const petTypeName = useMemo(() => {
    return valueToLabel(newPetData.petTypeId, PET_TYPES)
  }, [newPetData.petTypeId])

  const petBreedList = useMemo(() => {
    return (masterData.petBreeds ?? []).filter((item: any) => item.petType == newPetData.petTypeId)
      .map((item: any) => ({value: item.value, label: item.label}))
  }, [newPetData.petTypeId, masterData])

  const {
    reset,
    control,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      petBreedList: [
        {item: null}
      ],
    }
  })

  const {
    fields,
    append,
  } = useFieldArray({
    control,
    name: 'petBreedList',
  })

  useEffect(() => {
    if (newPetData.petBreedList) {
      reset({
        petBreedList: newPetData.petBreedList.split(',')
          .map((item: any) => ({item}))
      })
    }
  }, [])

  const onNext = (data: any) => {
    const petBreedList = data.petBreedList.filter((el: any) => el.item)
      .map((item: any) => item.item)
      .join(',')
    dispatch(setNewPetData({petBreedList}))
    navigate('/pets/new/ques5')
  }

  return (<>
    <Wrapper className="relative">
      <QuestionHeader
        index="4"
      >
        <div className="font-bold">
          <p className="leading-5">愛{petTypeName}の{petTypeName}種は？</p>
        </div>
      </QuestionHeader>
    </Wrapper>
    {fields.map((item, index) => {
      return (
        <SearchSelect
          key={item.id}
          name={`petBreedList.${index}.item`}
          control={control}
          options={petBreedList}
          placeholder={`タップして選択or${petTypeName}種名を入力し検索`}
        />
      )
    })}
    {errors.petBreedList?.root?.message && <span className="text-rose-500 font-bold text-sm mt-[-8px] mb-1">{errors.petBreedList?.root?.message}</span>}
    <div className="text-center">
      <button
        type="button"
        className="font-bold text-black"
        onClick={() => append({item: null})}
      >＋ミックスの場合、猫種を追加</button>
    </div>
    <div className="text-sm font-bold text-center mt-16 mb-20">
      <p>タップして該当する{petTypeName}種を選択してください。</p>
      <p>{petTypeName}種名の一部を入力して検索することもできます。</p>
      <p>被毛の長さ、毛色の種類がない場合も、<br />該当する{petTypeName}種を選択頂ければ問題ありません。</p>
    </div>
    <button
      className="btn bg-green"
      onClick={handleSubmit(onNext)}
    >次へ</button>
    <button className="btn sky-1 mt-4"
      onClick={() => navigate('/pets/new/ques3')}
    >戻る</button>
  </>)
}

export default Question4

const Wrapper = styled.div`
  
`

