import classNames from "classnames"
import styled from "styled-components"
import { ContactFooter, Header } from "../common"
import { Controller } from "react-hook-form"

interface Props {
  isOpen: boolean,
  onClose: () => void,
}

const ContactModal = ({
  isOpen,
  onClose
}: Props) => {

  return (<>
    <Wrapper
      className={classNames({
        'menu-overlay': true,
        'show': isOpen
      })}
    >
      <div className="w-[90%] m-auto">
        <Header hiddenMenu/>
      </div>
      <div className="inner !pt-2">
        <div className="menu-wrapper">
          <div className="menu-body">
            <button
              className="btn-close z-10"
              onClick={onClose}
            >
              <img src="/icon-close.png" alt="" />
            </button>
            <hr />
            <div className="text-black">
              <h3 className="font-bold mt-6 mb-4 sky-2">お困りの方へ</h3>
              <div className="text-sm mb-5">
                <p>・ログインができなくなった</p>
                <p>・操作方法がわからない</p>
                <p>・その他ご質問</p>
              </div>
              <div className="text-sm">
                <p>下記メールまたはLINE公式（問い合わせ）へご連絡ください。</p>
                <p>担当者が早急に応答いたします。</p>
              </div>
              <div className="relative mt-12">
                <a
                  href="mailto:info@5strands-japan.com"
                  className="block w-full leading-8 text-sm text-white font-bold rounded-lg text-center bg-sky-2" 
                >お問い合わせ窓口</a>
                <img
                  src="/img03.png"
                  alt=""
                  className="absolute bottom-0 right-0 h-28"
                />
              </div>
            </div>
            <div className="footer">
              <ContactFooter />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>  
  </>)
}
export default ContactModal

const Wrapper = styled.div`
  position: absolute;
  background: url(/bg.png) no-repeat center;
  background-size: cover;
  .menu-wrapper {
    flex: 1;
  }
  .footer {
    padding: 15px 15px 0 15px;
  }
`

