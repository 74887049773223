import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '.'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isLoading: false,
    isAuthenticated: false,
    registerData: {},
    newPetData: {},
    masterData: {},
    selectedPet: null,
    isEmptyPet: true,
  } as AppStateType,
  reducers: {
    resetState: (state) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.registerData = {}
      state.newPetData = {}
      state.masterData = {}
      state.selectedPet = null
      state.isEmptyPet = true
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload
    },
    
    setRegisterCurrentStep: (state, action) => {
      state.registerCurrentStep = action.payload
    },
    setRegisterData: (state, action) => {
      if (!action.payload) {
        state.registerData = {}
        return
      }
      state.registerData = {
        ...state.registerData,
        ...action.payload
      }
    },

    clearRegisterData: (state) => {
      state.registerData = {}
    },

    setNewPetData: (state, action) => {
      state.newPetData = {
        ...state.newPetData,
        ...action.payload
      }
    },

    clearNewPetData: (state) => {
      state.newPetData = {}
    },

    setMasterData: (state, action) => {
      state.masterData = {
        ...state.masterData,
        ...action.payload
      }
    },

    setSelectedPet: (state, action) => {
      state.selectedPet = action.payload
    },

    setIsEmptyPet: (state, action) => {
      state.isEmptyPet = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  resetState,
  setLoading,
  setIsAuthenticated,
  setRegisterCurrentStep,
  setRegisterData,
  clearRegisterData,
  setNewPetData,
  clearNewPetData,
  setMasterData,
  setSelectedPet,
  setIsEmptyPet,
} = appSlice.actions

export const isAuthenticated = (state: RootState) => state.app.isAuthenticated
export const isLoading = (state: RootState) => state.app.isLoading
export const getAppStates = (state: RootState) => state.app
export const getMasterData = (state: RootState) => state.app.masterData
export const currentSelectedPet = (state: RootState) => state.app.selectedPet

export default appSlice.reducer

interface AppStateType {
  isLoading: boolean,
  isAuthenticated: boolean,
  registerData: any,
  registerCurrentStep: 1,
  newPetData: any,
  masterData: any,
  selectedPet: any,
  isEmptyPet: boolean,
}
