/**
 * @note
 * for hook alternative of route element composition:
 * - https://reactrouter.com/docs/en/v6/upgrading/v5#use-useroutes-instead-of-react-router-config
 * - https://reactrouter.com/docs/en/v6/examples/route-objects
 *
 * might need to take notes on:
 * - https://reactrouter.com/docs/en/v6/upgrading/v5#note-on-link-to-values
 */

import { Routes, Route, Navigate } from "react-router-dom";

import AppLayout from "../components/layouts/App";
import RequireAuth from "../components/auth/RequireAuth";
import { routes, privateRoutes } from "./routes";
import Page404 from "../pages/404";
import Middleware from "../components/auth/Middlewra";

const Routings = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route
          path="/"
          element={<Navigate to="/pets/list" />}
        />
        {routes.map((routeProps) => (
          <Route {...routeProps} key={routeProps.path as string} />
        ))}
        {privateRoutes.map(({ element, isSelectedPet, ...privateRouteProps }) => (
          <Route
            element={
              <RequireAuth>
                <Middleware isSelectedPet={isSelectedPet}>
                  {element as JSX.Element}
                </Middleware>
              </RequireAuth>
            }
            {...privateRouteProps}
            key={`privateRoute-${privateRouteProps.path}`}
          />
        ))}
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Routings;
