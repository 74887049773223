import { Link } from "react-router-dom"
import styled from "styled-components"

const Footer = () => {
  return (<>
    <Wrapper>
      <a href="https://business.form-mailer.jp/fms/4ddd9325251914" target="_blank" className="link1">
        <i>
          <img src="/icon-msg.png" alt="" />
        </i>
        <span>リストにない<br />ペットフードを申請</span>
      </a>
      <Link to={'/onepoint-advice'} className="btn-onepoint">
        <img src="/img01.png" alt="" />
      </Link>
    </Wrapper>
  </>)
}

export default Footer

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 -35px;
  .link1 {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    padding: 4px 8px;
    background-color: #fff;
    i {
      font-size: 0;
    }
    img {
      width: 18px;
    }
    span {
      font-size: 14px;
      line-height: 1;
      font-weight: bold;
    }
  }
`
