import { useEffect, useMemo, useState } from "react"
import { Link, createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useApp } from "../../hooks"
import { PetfoodService } from "../../api"
import ReactPaginate from 'react-paginate';
import { LinkGroup } from "../../components/common"

const FoodSearch = () => {
  const navigate = useNavigate()
  const {id: petId} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const {setIsLoading} = useApp()
  const [pNumber, setPNumber] = useState(searchParams.get('pNumber') ? parseInt(searchParams.get('pNumber') ?? '1') : 1)
  const [foods, setFoods] = useState([])
  const [totalPage, setTotalPage] = useState(1)

  const params = useMemo(() => {
    return Object.fromEntries(searchParams)
  }, [searchParams])

  useEffect(() => {
    const getList = async (params: any = {}) => {
      if (!params.pNumber) params.pNumber = 1

      try {
        setIsLoading(true)
        const {data} = await PetfoodService.search({
          pid: petId,
          ...params,
        })
        const {status, petfoods, totalPage} = data
        if (status == 'ok') {
          setFoods(petfoods)
          setTotalPage(totalPage)
        } else {
          setFoods([])
          setTotalPage(1)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    getList(params)
  }, [params])

  const onClickItem = (index: number) => {
    const food: any = foods[index]
    if (!food) return
    navigate(`/foods/${food.foodId}`, {
      state: { food }
    })
  }

  const onPageClick = (e: any) => {
    console.log(e.selected)
    setSearchParams({
      ...params,
      pNumber: e.selected + 1
    })
    window.scrollTo(0, 0)
  }

  return (<>
    <Wrapper>
      <div className="header">
        <h1 className="relative text-center min-h-16 font-bold pt-2 z-0 mt-3 mb-6">
          <span className="text-lg">フード検索一覧</span>
          <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">FOOD<br />research</span>
        </h1>
      </div>
      <div className="flex flex-col gap-7">
        {foods.map((el, index) => {
          return (
            <Item
              key={index}
              index={index+1}
              item={el}
              totalCount={foods.length}
              onClick={() => onClickItem(index)}
            />
          )
        })}
      </div>
      {totalPage > 1 && <>
        <ReactPaginate
          breakLabel="..."
          nextLabel="次へ"
          onPageChange={onPageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={totalPage}
          forcePage={pNumber - 1}
          previousLabel="前へ"
          renderOnZeroPageCount={null}
          className="pagination pagination-list"
          activeClassName="active"
        />
      </>}
      <LinkGroup petId={petId} />
    </Wrapper>
  </>)
}

export default FoodSearch

const Item = ({
  item,
  onClick,
}: any) => {

  return (<>
    <div className="flex gap-4">
      <figure
        className="w-32"
        onClick={onClick}
      > 
        <img src={item?.imageSrc ?? '/noimg.png'} alt="" className="w-full" />
      </figure>
      <div className="flex-1">
        <div className="text-sm mb-1">{item?.makerName}</div>
        <div className="font-bold">{item?.productName}</div>
        <div className="text-sm mt-2 leading-5 line-clamp-5" dangerouslySetInnerHTML={{__html: item?.rawMaterials}}></div>
      </div>
    </div>
  </>)
}

const Wrapper = styled.div`
  .header {
    h1 {
      font-size: 28px;
      font-weight: bold;
    }
  }
`