import { Link } from "react-router-dom"

const LinkGroup = ({
  petId,
  className = '',
}: any) => {
  return (<>
    <div className={`flex justify-center max-w-[520px] gap-2 mt-10 mx-auto ${className}`}>
      <div>
        <Link to={`/pets/${petId}/info`} className="">
          <img src="/btn-mypage.png" alt="" />
        </Link>
      </div>
      <div>
        <Link to="/pets/list" className="">
          <img src="/btn-pets.png" alt="" />
        </Link>
      </div>
    </div>
  </>)
}

export default LinkGroup
