import { Control, Controller, useFieldArray, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { SearchSelect } from "."
import { useMemo } from "react"
import { valueToLabel } from "../../helpers/functions"
import { PET_TYPES } from "../../helpers/enums"
import { useAppSelector } from "../../hooks"
import { getAppStates } from "../../store/appSlice"

interface Props {
  label?: string,
  name?: string,
  control?: Control<any>,
  readOnly?: boolean,
}

const BreedSelector = ({
  label,
  name = 'petBreedList',
}: Props) => {
  const { masterData } = useAppSelector(getAppStates)

  const {
    watch,
    control,
  } = useFormContext()

  const {
    fields,
    append,
  } = useFieldArray({
    control,
    name,
  })

  const petTypeId = watch('petTypeId')

  const petBreedList = useMemo(() => {
    if (!petTypeId) return []
    return (masterData.petBreeds ?? []).filter((item: any) => item.petType == petTypeId)
      .map((item: any) => ({value: item.value, label: item.label}))
  }, [petTypeId, masterData])

  const petTypeName = useMemo(() => {
    return valueToLabel(petTypeId, PET_TYPES)
  }, [petTypeId])

  return (<>
    <Wrapper className="mb-[14px]">
      {!!label && <label>{label}</label>}
      {fields.map((field, index) => {
        return (
          <SearchSelect
            key={field.id}
            name={`${name}[${index}].item`}
            control={control}
            options={petBreedList}
            placeholder={`タップして選択or${petTypeName}種名を入力し検索`}
          />
        )
      })}
      <div className="text-center -mt-1.5">
        <button
          type="button"
          className="font-bold"
          onClick={() => append({item: null})}
        >＋ミックスの場合、猫種を追加</button>
      </div>
    </Wrapper>
  </>)
}

export default BreedSelector

const Wrapper = styled.div`
  margin-bottom: 14px;
  > label {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
  }
`
