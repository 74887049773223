import { CSSProperties, useState } from "react"
import { Control, Controller } from "react-hook-form"
import styled from "styled-components"

interface Props {
  label?: string,
  value?: string,
  type?: string,
  name?: string,
  control?: Control<any>,
  readOnly?: boolean,
  onChange?: (val: string) => void,
  controlStyle?: CSSProperties
}

const FormPasword = ({
  label,
  value,
  name = 'password',
  onChange,
  control,
  readOnly = false,
  controlStyle = {},
}: Props) => {
  const [isShow, setIsShow] = useState(false)

  return (<>
    <Wrapper>
      {!!label && <label>{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({field, fieldState}) => {
          const { error, invalid } = fieldState
          return <>
            <div className="relative">
              {!isShow ? <>
                <input
                  type={'password'}
                  {...field}
                  readOnly={readOnly}
                  className="text-black"
                />
              </> : <>
                <input
                  type={'text'}
                  {...field}
                  readOnly={readOnly}
                  className="text-black"
                />
              </>}
              <button
                type="button"
                className="btn-show absolute top-2 right-2 z-10 !mt-0"
                onClick={() => setIsShow(!isShow)}
              >
                <img src="/icon-eye2.png" className="max-w-8 w-8 h-6" alt="" />
              </button>
            </div>
            {invalid && <span className="text-rose-500 font-bold text-sm">{error?.message}</span>}
          </>
        }}
      />
    </Wrapper>
  </>)
}

export default FormPasword

const Wrapper = styled.div`
  margin-bottom: 14px;
  label {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    height: 40px;
    padding: 10px 15px;
    background-color: #fff;
    border: none;
    outline: none;
  }
`
