import { ReactNode } from "react"
import styled from "styled-components"

interface Props {
  index: string,
  children?: ReactNode,
}

const Header = ({
  index,
  children,
}: Props) => {
  return (<>
    <Wrapper className="text-center mb-4 flex-1">
      <p className="futurabt text-[15px] tracking-wider mb-3">QUESTION</p>
      <p className="futurabt text-[15px]">
        <span className="text-5xl mr-1">{index}</span>/11
      </p>
      {index === '1' && <>
        <p
          className="text-center text-sm font-bold mt-2 mb-[-4px]"
          style={{
            color: '#fabe00',
          }}
        >検査被毛を送付前に登録が必要となります。</p>
      </>}
      <h1 className="relative flex justify-center align-center min-h-[84px] pt-3 z-0">{children}
        <span className="inline-block absolute top-3 left-0 w-full futurabt text-3xl text-center sky-2 tracking-widest uppercase z-[-1]">cure <br />allergies</span>
      </h1>
    </Wrapper>
  </>)
}

export default Header

const Wrapper = styled.div`
  
`