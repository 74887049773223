import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { useApp } from "../../hooks"
import { TestService } from "../../api"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"

const Mailing = () => {
  const {id: testId} = useParams()
  const navigate = useNavigate()
  const {setIsLoading} = useApp()
  const [test, setTest] = useState<any>(null)

  useEffect(() => {
    const getTest = async (testId: string) => {
      try {
        setIsLoading(true)
        const {data} = await TestService.get({id: testId})
        const {status, test} = data
        if (status == 'ok') {
          setTest(test)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    if (testId) {
      getTest(testId)
    }
  }, [testId])

  const onFinishSend = async () => {
    try {
      setIsLoading(true)
      const {data} = await TestService.send({
        petId: test?.petId,
        testId,
      })
      navigate(`/test/${testId}/send/complete`)
    } catch (err: any) {
      console.log(err)
      toast.error(err?.data?.messageJp || 'エラーが発生しました')
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper>
      <h1 className="relative text-center min-h-16 font-bold pt-2 z-0 mt-3 mb-6">
        <span className="text-lg">{test?.petName}の<br />新規検査御申込受付が<br />完了しました。</span>
        <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">FOOD<br />research</span>
      </h1>
      <p className="text-center mt-16 mb-24">被毛を20本程採取し、お送りください。<br />
      郵送が終わりましたらこちらのボタンを<br />
      押してください。</p>
      <button
        className="btn-box bg-blue"
        onClick={onFinishSend}
      >郵送完了</button>
    </Wrapper>
  </>)
}

export default Mailing

const Wrapper = styled.div`
`
