import './App.css';
import { BrowserRouter, RouterProvider, createBrowserRouter } from "react-router-dom";
import Routings from './router/Routings';
import { AppProvider } from './hooks/useApp';
import { Provider } from 'react-redux'
import { store } from './store'
import { Toaster } from 'react-hot-toast';

const router = createBrowserRouter([
  { path: "*", Component: Routings },
]);



function App() {
  return (
    <Provider store={store}>
      <AppProvider>
        <Toaster />
        <RouterProvider router={router}/>
      </AppProvider>
    </Provider>
  );
}

export default App;
