import classNames from "classnames"
import styled from "styled-components"

interface Props {
  className?: string
}

const ContactFooter = ({
  className = '',
}: Props) => {
  return (<>
    <Wrapper className={className}>
      <label>◆メール</label>
      <p>mail_info@5strands-japan.com</p>
      <p>営業時間/9:00～22:00</p>
      <label>◆公式LINE（問い合わせ）</label>
      <div className="btn-link-wrapper">
        <a
          href="https://page.line.me/177saozs?oat__id=2669049&openQrModal=true"
          className="btn-line"
          target="_blank"
        >
          <img src="/btn-login.png" alt="" className="h-10" />
        </a>
      </div>
    </Wrapper>  
  </>)
}

export default ContactFooter

const Wrapper = styled.div`
  font-size: 14px;
  color: #000;
  label:not(:first-child) {
    display: inline-block;
    margin-top: 15px;
  }
  .btn-link-wrapper {
    font-size: 0;
    margin-top: 10px;
  }
`

