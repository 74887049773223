import classNames from "classnames"
import { Link, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { ContactFooter } from "../common"
import { useApp, useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates, resetState } from "../../store/appSlice"
import Cookies from "js-cookie"

interface Props {
  isOpen: boolean,
  onClose: () => void,
}

const MenuModal = ({
  isOpen,
  onClose
}: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { setUser } = useApp()
  const { selectedPet } = useAppSelector(getAppStates)

  const showPage = (url: string) => {
    onClose()
    navigate(url)
  }

  const logout = () => {
    onClose()
    setUser(null)
    dispatch(resetState())
    Cookies.remove('user_access_token')
  }

  return (<>
    <Wrapper
      className={classNames({
        'menu-overlay': true,
        'show': isOpen
      })}
    >
      <div className="inner">
        <div className="menu-wrapper">
          <div className="menu-body">
            <button
              className="btn-close z-10"
              onClick={onClose}
            >
              <img src="/icon-close.png" alt="" />
            </button>
            <hr />
            <div className="news">
              <button
                className="menu-link menu-link--news"
                onClick={() => showPage('/news')}
              >
                <img src="/icon-bell.png" alt="" />お知らせ
              </button>
            </div>
            <hr />
            <div className="links">
              <div>
                <button
                  className="menu-link"
                  onClick={() => showPage('/profile')}
                >飼い主登録情報の編集・変更</button>
              </div>
              {selectedPet && <>
                <div>
                  <button
                    className="menu-link"
                    onClick={() => showPage(`/pets/${selectedPet.id}/edit`)}
                  >ペット情報を編集・変更</button>
                </div>
                <div>
                  <button
                    className="menu-link"
                    onClick={() => showPage('/pet/photo')}
                  >ペットプロフィール画像変更</button>
                </div>
              </>}
              <div>
                <button
                  className="menu-link"
                  onClick={() => showPage('/change-email')}
                >メールアドレスの変更</button>
              </div>
              <div>
                <button
                  className="menu-link"
                  onClick={() => showPage('/change-password')}
                >パスワードの変更</button>
              </div>
            </div>
            <hr />
            <div className="footer">
              <ContactFooter />
              <button
                className="btn-logout"
                onClick={logout}
              >ログアウト</button>
            </div>
          </div>
        </div>
        <div className="flex justify-center text-sm">
          <button onClick={() => showPage('/terms')}>利用規約</button>
          <span>、</span>
          <button onClick={() => showPage('/privacy')}>個人情報保護方針</button>
          <span>の取扱いについて</span>
        </div>
      </div>
    </Wrapper>  
  </>)
}
export default MenuModal

const Wrapper = styled.div`
  background: url(/bg.png) no-repeat center;
  background-size: cover;
  .menu-wrapper {
    flex: 1;
  }
  .menu {
    position: relative;
    padding: 40px 30px 20px;
    background-color: #e3e3e3;
    border-radius: 45px;
    .btn-close {
      position: absolute;
      top: 25px;
      right: 20px;
      width: 28px;
      height: 28px;
      padding: 0;
      background-color: transparent;
      z-index: 1;
    }
  }
  .privacy {
    font-size: 14px;
    text-align: center;
    color: #fefefe;
  }
  .menu-link {
    position: relative;
    font-weight: bold;
    color: #000;
    &:after {
      content: '';
      position: absolute;
      left: calc(100% + 8px);
      top: calc(50% - 8px);
      width: 16px;
      height: 16px;
      background: url(/icon-double-right2.png) no-repeat center;
      background-size: contain;
    }
  }
  .menu-link--news {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    img {
      height: 22px;
    }
  }
  .links {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 15px 0 15px 15px;
  }
  .news {
    position: relative;
    padding: 30px 0 30px 15px;
  }
  .footer {
    padding: 15px 15px 0 15px;
  }
`

