import styled from "styled-components"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import { AuthService } from "../../api"
import { useApp } from "../../hooks"

const RegisterVerify = () => {
  const navigate = useNavigate()
  const { setIsLoading } = useApp()
  const [searchParams] = useSearchParams()
  const [msg, setMsg] = useState('')

  const params = useMemo(() => {
    return Object.fromEntries(searchParams)
  }, [searchParams])

  useEffect(() => {
    const verify = async (params: any = {}) => {
      try {
        setIsLoading(true)
        const {data} = await AuthService.verify(params)
        const { status, messageJp } = data
        if (status == 'ok') {
          setMsg('アカウントの本登録が完了しました。')
        } else {
          setMsg(messageJp ?? 'アカウントの本登録に失敗しました。')
        }
      } catch (err: any) {
        const { data } = err
        setMsg(data.messageJp ?? 'アカウントの本登録に失敗しました。')
      } finally {
        setIsLoading(false)
      }
    }

    if (!params['k'] || !params['r']) {
    } else {
      verify({
        k: params['k'],
        r: params['r']
      })
    }
  }, [])

  return (
    <Wrapper>
      <h1 className="title">飼い主登録<span>owner</span></h1>
      <p className="text-center text-sm min-h-5">{msg}</p>
      <button
        className="btn-box bg-blue !mt-10 !h-12"
        onClick={() => navigate('/login')}
      >ログインページに戻る</button>
    </Wrapper>
  )
}

export default RegisterVerify

const Wrapper = styled.div`
  h1 {
    padding: 20px 0;
    span {
      top: 28px;
    }
  }
`
