import styled from "styled-components"
import { BreedSelector, CheckGroup, DateSelector, Input, RadioGroup, SearchSelect, Select, Textarea } from "../../components/common"
import { array, mixed, object, string } from "yup"
import { FormProvider, set, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { PET_SEXES, PET_TYPES, YN } from "../../helpers/enums"
import { useParams } from "react-router-dom"
import { useEffect, useMemo } from "react"
import { PetsService } from "../../api"
import { useApp, useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates } from "../../store/appSlice"
import toast from "react-hot-toast"

const schema = object().shape({
  petTypeId: string().required('ペットの種類を選択してください'),
  name: string().required('ペットのお名前を入力してください'),
  sex: string().required('ペットの性別を選択してください'),
  bodyWeight: string().required('ペットの体重を入力してください'),
  birthDate: string().required('ペットの生年月日を選択してください'),
  petHealthTroubleList: array().min(1, '健康状態の悩みを選択してください'),
  healthTroubleComment: string().nullable(),
  isHospitalWorking: string().required('現在通院中ですか？を選択してください'),
  isMedicineWorking: string().required('現在服用中の薬はありますか？を選択してください'),
  medicineComment: string().nullable(),
  petBreedList: array()
    .of(object().shape({
      item: mixed().nullable()
    }))
    .min(1, 'ペット種類を選択してください')
    .test('atLeastOne', 'ペット種類を選択してください', (value) => (value ?? []).some((row: any) => row.item !== null)),
})

const Edit = () => {
  const { id } = useParams()
  const { setIsLoading } = useApp()
  const { masterData } = useAppSelector(getAppStates)

  const weightOptions = useMemo(() => {
    // 0から100kgまで0.5kg単位で体重を設定
    const options = []
    for (let i = 1; i <= 200; i++) {
      options.push({
        value: i / 2,
        label: `${i / 2}kg`
      })
    }
    return options
  }, [])

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: {errors},
  } = methods

  useEffect(() => {
    const getPet = async (id: string) => {
      try {
        setIsLoading(true)
        const { data } = await PetsService.get({id})
        const { status, petDetail } = data
        if (status == 'ok') {
          reset({
            petTypeId: petDetail.petTypeId,
            name: petDetail.name,
            sex: petDetail.sex,
            bodyWeight: petDetail.bodyWeight,
            birthDate: petDetail.birthDate,
            petHealthTroubleList: (Array.isArray(petDetail.petHealthTroubleList) ? petDetail.petHealthTroubleList : []).map((item: any) => item.id)
              .reduce((acc: string[], el: string) => {
                if (!acc.includes(el)) {
                  acc.push(el);
                }
                return acc;
              }, []),
            healthTroubleComment: petDetail.healthTroubleComment,
            isHospitalWorking: petDetail.isHospitalWorking ? '1' : '0',
            isMedicineWorking: petDetail.isMedicineWorking ? '1' : '0',
            medicineComment: petDetail.medicineComment,
            petBreedList: (Array.isArray(petDetail.petBreedList) ? petDetail.petBreedList : []).map((item: any) => ({item: item.id})),
          })
        }
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    if (id) {
      getPet(id)
    }
  }, [id])


  const onSubmit = async (values: any) => {
    const {petBreedList, petHealthTroubleList, ...rest} = values
    try {
      setIsLoading(true)
      const { data } = await PetsService.update({
        id,
        ...rest,
        petBreedList: petBreedList.map((item: any) => item.item).join(','),
        petHealthTroubleList: petHealthTroubleList.join(','),
      })
      if (data.status === 'ok') {
        toast.success('ペット情報を更新しました。')
      } else {
        toast.error('ペット情報の更新に失敗しました。')
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper>
      <h1 className="relative text-center font-bold z-0 mt-4 mb-6">
        <span className="text-lg">ペット情報の編集・変更</span>
        <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">PET</span>
      </h1>
      <div className="form-wrapper">
        <FormProvider {...methods}>
          <RadioGroup
            label="ペットの種類"
            name="petTypeId"
            control={control}
            options={PET_TYPES}
          />
          <Input
            label="ペットのお名前"
            name="name"
            control={control}
          />
          <BreedSelector
            label="ペットの種類"
          />
          <RadioGroup
            label="ペットの性別"
            name="sex"
            control={control}
            options={PET_SEXES}
          />
          <Select
            label="ペットの体重"
            name="bodyWeight"
            control={control}
            options={weightOptions}
          />
          <DateSelector
            label="生年月日"
            name="birthDate"
            control={control}
          />
          <CheckGroup
            label="ペットの健康上の悩み"
            name="petHealthTroubleList"
            control={control}
            options={masterData.petHealthTroubles ?? []}
            size="small"
          />
          <Textarea
            label="その他、お悩みがあれば記入してください"
            name="healthTroubleComment"
            control={control}
            rows={3}
          />
          <RadioGroup
            label="現在通院中ですか？"
            name="isHospitalWorking"
            control={control}
            options={YN}
          />
          <RadioGroup
            label="現在服用中の薬はありますか？"
            name="isMedicineWorking"
            control={control}
            options={YN}
          />
          <Textarea
            label="服用中の薬を教えてください。"
            name="medicineComment"
            control={control}
            rows={3}
          />
          <button
            className="btn bg-green"
            onClick={handleSubmit(onSubmit)}
          >更新</button>
        </FormProvider>
      </div>
    </Wrapper>
  </>)
}

export default Edit

const Wrapper = styled.div`
  .btn {
    margin-top: 40px;
  }
`
