import { Link } from "react-router-dom"
import styled from "styled-components"
import { PetsService } from "../../api"
import { useEffect, useState } from "react"

const List = () => {
  const [pets, setPets] = useState<any[]>([])

  useEffect(() => {
    const getPets = async () => {
      try {
        const { data } = await PetsService.list()
        const { status, pets } = data
        if (status == 'ok') {
          setPets(pets)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getPets()
  }, [])

  return (<>
		<Wrapper className="pt-12">
      <h1 className="relative flex justify-center align-center font-bold pt-3 mb-12 z-0">どなたの内容を見ますか？
        <span className="inline-block absolute -top-1 left-0 w-full futurabt text-3xl text-center sky-2 tracking-widest uppercase z-[-1]">Login</span>
      </h1>
      <div className="flex flex-wrap gap-x-8 gap-y-6 w-64 mx-auto mb-6">
        {pets.map((pet) => (
          <Link key={pet.id} to={`/pets/${pet.id}/info`}>
            <img src={pet.imageUrl ?? '/noimg.png'} alt="" className="w-28 h-28 object-cover rounded-full" />
          </Link>
        ))}
      </div>
      <div className="text-center">
        <Link to={'/pets/new/ques1'} className="relative inline-block w-28 h-28 border border-white rounded-full plus"></Link>
        <p className="mt-1">ペットの追加</p>
      </div>
		</Wrapper>
	</>)
}

export default List

const Wrapper = styled.div`
	.plus {
    &:after, &:before {
      content: '';
      position: absolute;
      background-color: #fff;
      border-radius: 999px;
      z-index: 1;
    }
    &:before {
      width: 2px;
      height: 26px;
      top: calc(50% - 13px);
      left: calc(50% - 1px);
    }
    &:after {
      width: 26px;
      height: 2px;
      left: calc(50% - 13px);
      top: calc(50% - 1px);
    }
  }
`
