import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { mixed, object } from "yup"
import { useApp, useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates, setIsEmptyPet, setNewPetData } from "../../store/appSlice"
import { useEffect, useState } from "react"
import { PetsService } from "../../api"

const schema = object().shape({
  file: mixed().defined().nullable(),
})

const Question11 = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { setIsLoading } = useApp()
  const { newPetData } = useAppSelector(getAppStates)

  const [file, setFile] = useState<File | null>(null)

  const {
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      file: null,
    }
  })

  const onChangeFile = (e: any) => {
    const file = e.target.files[0]
    
    if (file) {
      setValue('file', file)
      setFile(file)
    }
  }

  const onSubmit = async (values: any) => {
    const data = {
      ...newPetData,
      file: values.file,
    }

    try {
      setIsLoading(true)
      const { data: resData } = await PetsService.create(data)
      const { status } = resData
      if (status == 'ok') {
        dispatch(setNewPetData({}))
        dispatch(setIsEmptyPet(false))
        navigate('/pets/list')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper className="flex flex-col flex-1">
      <div className="flex-1 mb-5">
        <QuestionHeader
          index="11"
        >
          <div className="font-bold">
            <p className="leading-5 mt-5">{newPetData.name ?? 'ペット'}の<br />
              ステキなお写真を<br />
              アップロードしてください。</p>
          </div>
        </QuestionHeader>
        <div className="text-center mb-10">
          <div className="fs-0">
            <label className="relative inline-flex align-center justify-center w-36 h-36 rounded-full bg-white overflow-hidden plus-center">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={onChangeFile}
              />
              {file && <>
                <img src={URL.createObjectURL(file)} alt="" className="w-full h-full object-cover opacity-50" />
              </>}
            </label>
          </div>
          <p className="font-bold mt-1">お写真アップロード</p>
        </div>
      </div>
      <button
        className="btn bg-green"
        onClick={handleSubmit(onSubmit)}
      >登録完了</button>
      <button className="btn sky-1 mt-4"
        onClick={() => navigate('/pets/new/ques10')}
      >戻る</button>
    </Wrapper>
  </>)
}

export default Question11

const Wrapper = styled.div`
  .plus-center {
    &:before, &:after {
      content: '';
      position: absolute;
      border-radius: 999px;
      background-color: #000;
      z-index: 10;
    }
    &:before {
      width: 5px;
      height: 40px;
      top: calc(50% - 20px);
      left: calc(50% - 2.5px);
    }
    &:after {
      height: 5px;
      width: 40px;
      left: calc(50% - 20px);
      top: calc(50% - 2.5px);
    }
  }
`

