import { createContext, useContext, useEffect, useState } from "react";
import { AuthService, MasterService } from "../api";
import { useAppDispatch, useAppSelector } from "./useStore";
import { getAppStates, setIsAuthenticated } from "../store/appSlice";

interface Props {
  children?: React.ReactNode;
}

interface AppContextInterface {
  user: any,
  setUser: (data: any) => void,
  isLoading: boolean,
  setIsLoading: (flg: boolean) => void,
}

const AppContext = createContext<AppContextInterface>({
  user: null,
  setUser: (data: any) => {},
  isLoading: false,
  setIsLoading: (flg: boolean) => {},
})

export const AppProvider = ({
  children
}: Props) => {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAppSelector(getAppStates)
  const [user, setUser] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<any>(false)

  const getCurrentUser = async () => {
    try {
      setIsLoading(true)
      const { data } = await AuthService.currentUser()
      const { status, account } = data
      setUser(account)
      if (status === 'ok') {
        await MasterService.fetch()(dispatch)
      } else {
        dispatch(setIsAuthenticated(false))
      }
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isAuthenticated)
      getCurrentUser()
  }, [isAuthenticated])

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  return useContext(AppContext);
};