import styled from "styled-components"
import { BtnConfirmSearch } from "../../components/common/buttons"
import { useNavigate, useParams } from "react-router-dom"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { useApp } from "../../hooks"
import { PetsService, TestService } from "../../api"

const Detail = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const {setIsLoading} = useApp()

  const [result, setResult] = useState<any>(null)

  useEffect(() => {
    const getInfo = async (id: string) => {
      try {
        setIsLoading(true)
        const {data} = await TestService.get({id})
        const {status, test} = data
        if (status == 'ok') {
          const result = JSON.parse(test.resultImageUrl)
          setResult({
            ...test,
            ...result
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    console.log(result)

    if (id) {
      getInfo(id)
    }
  }, [id])

  const onDownloadPdf = () => {
    if (!result?.pdf) return
    window.open(result.pdf, '_blank')
  }

  return (<>
    <Wrapper>
      <h1 className="relative text-center min-h-16 font-bold pt-2 z-0 mt-3 mb-6">
        <span className="text-lg">{result?.petName}さんの<br />新規検査御申込受付が<br />完了しました。</span>
        <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">FOOD<br />research</span>
      </h1>
      <h2 className="text-lg border-t-2 border-white text-center pt-3 mb-7">現在の進行状況です</h2>
      <div>
        <label
          className={classNames({
            'label relative block text-center font-bold py-2 mb-8': true,
            'bg-sky text-black': true,
          })}
        >新規受付完了</label>
        <label
          className={classNames({
            'label relative block text-center font-bold py-2 mb-8': true,
            'bg-sky text-black': true,
          })}
        >検体（被毛）郵送完了</label>
        <label
          className={classNames({
            'label relative block text-center font-bold py-2 mb-8': true,
            'bg-sky text-black': result?.inTesting >= 2,
            'bg-gray-1': result?.inTesting < 2,
          })}
        >検体（被毛）USラボ手配完了</label>
        <label
          className={classNames({
            'label relative block text-center font-bold py-2 mb-8': true,
            'bg-sky text-black': result?.inTesting >= 2,
            'bg-gray-1': result?.inTesting < 2,
          })}
        >USラボによる検査実施中</label>
      </div>
      <div className="w-72 m-auto mb-11">
        <BtnConfirmSearch
          label="検査完了結果確認"
          disabled={result?.inTesting < 3 || !result?.pdf}
          onClick={onDownloadPdf}
        />
      </div>
      <button
        className="btn bg-green"
        onClick={() => navigate(`/pets/${id}/info`)}
      >マイページ</button>
    </Wrapper>
  </>)
}

export default Detail

const Wrapper = styled.div`
  .label {
    &:after {
      content: '▼';
      position: absolute;
      left: 50%;
      top: calc(100% + 5px);
      font-size: 14px;
      color: #bcbcbc;
      transform: translateX(-50%);
    }
    &.bg-sky:after {
      color: #00b8e7;
    }
  }
`
