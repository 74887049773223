import styled from "styled-components"
import { Input, LinkGroup } from "../../components/common"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import dayjs from "dayjs";
import { useApp, useAppSelector } from "../../hooks";
import { getAppStates } from "../../store/appSlice";
import { TestService } from "../../api";

const schema = object().shape({
  testUniqueCode: string().required('個別管理番号を入力してください'),
  testDate: string().required('検体採取日を入力してください'),
})

const Sinsei = () => {
  const navigate = useNavigate()
  const {selectedPet} = useAppSelector(getAppStates)
  const { setIsLoading } = useApp()

  const {
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      testUniqueCode: '',
      testDate: dayjs().format('YYYYMMDD'),
    }
  })

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true)
      const {data} = await TestService.create({
        petId: selectedPet?.id,
        ...values
      })
      if (data.status === 'ok') {
        navigate(`/test/${data.testId}/send`)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper>
      <h1 className="relative flex justify-center align-center text-center h-16 font-bold z-0 mt-3 mb-6">
        <span className="text-lg">{selectedPet?.name}さんの<br />新規検査御申込を行います</span>
        <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">FOOD<br />research</span>
      </h1>
      <div className="form-group">
        <label className="form-label"><span className="step">STEP1</span>個別管理番号</label>
        <Input
          name="testUniqueCode"
          control={control}
          controlClassName="!h-16 text-center"
          placeholder="検査キッド内の個別管理番号を入力"
        />
      </div>
      <div className="form-group">
        <label className="form-label"><span className="step">STEP1</span>検体採取日</label>
        <Controller
          name="testDate"
          control={control}
          render={({ field: {name, value, onChange} }) => {
            return (
              <div className="relative">
                <DatePicker
                  name={name}
                  selected={value ? dayjs(value).toDate() : null}
                  onChange={(value) => onChange(dayjs(value).format('YYYYMMDD'))}
                  className="w-full h-16 py-[10px] px-16 text-center"
                  dateFormat={'yyyy/MM/dd'}
                />
                <i className="absolute top-3 left-3 w-12">
                  <img src="/icon-calendar.png" alt="" className="w-10 h-10" />
                </i>
              </div>
            )
          }}
        />
      </div>
      <button className="btn-box bg-blue"
        onClick={handleSubmit(onSubmit)}
      >申　請</button>
      <LinkGroup petId={selectedPet?.id} />
    </Wrapper>
  </>)
}

export default Sinsei

const Wrapper = styled.div`
  .form-group {
    position: relative;
    margin-bottom: 70px;
    &:after {
      content: '▼';
      position: absolute;
      color: #282828;
      font-size: 28px;
      line-height: 1;
      top: calc(100% + 18px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
`
