import classNames from "classnames"
import { CSSProperties } from "react"
import { Control, Controller } from "react-hook-form"
import styled from "styled-components"

interface Props {
  label?: string,
  name?: string,
  value?: string,
  control?: Control<any>,
  onChange?: (val: string) => void,
  options?: Array<any>,
  size?: 'small' | 'default',
  dir?: 'horizontal' | 'vertical',
  readOnly?: boolean,
}

const FormRadioGroup = ({
  label,
  name = 'radio',
  value,
  control,
  onChange,
  options = [],
  size = 'default',
  dir = 'horizontal',
  readOnly = false,
}: Props) => {
  return (<>
    <Wrapper>
      {!!label && <label>{label}</label>}
      <Controller
        control={control}
        name={name}
        render={({field: {name, value, onChange}, fieldState}) => {
          const { error, invalid } = fieldState

          return <>
            <div className={classNames({
              'options': true,
              'options-size--default': size === 'default',
              'options-size--small': size === 'small',
              'flex-col gap-0.5': dir === 'vertical',
              'gap-x-4 gap-y-1': dir === 'horizontal' && size === 'default',
              'gap-x-3 gap-y-1': dir === 'horizontal' && size === 'small',
            })}>
              {options.map((el: any) => {
                return (<div key={el.value} className="fs-0">
                  <label
                    className={classNames({
                      'pointer-events-none': readOnly,
                      'text-base': true,
                    })}
                  >
                    <input
                      type="radio"
                      name={name}
                      value={el.value}
                      onChange={(e) => onChange(e.target.value)}
                      checked={el.value == value}
                      className={classNames({
                        'pointer-events-none': readOnly
                      })}
                    /><span>{el.label}</span>
                  </label>
                </div>)
              })}
            </div>
            {invalid && <span className="text-rose-500 font-bold text-sm">{error?.message}</span>}
          </>
        }}
      />
    </Wrapper>
  </>)
}

export default FormRadioGroup

const Wrapper = styled.div`
  margin-bottom: 14px;
  > label {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .options {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    label {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      input[type=radio] {
        width: 16px;
        height: 16px;
        margin: 0;
        border: none;
      }
    }
  }
  .options-size--default {
    font-size: 16px;
    label {
      gap: 12px;
      input[type=radio] {
        width: 18px;
        height: 18px;
        margin-top: 2px;
      }
    }
  }
`
