import client from "./client"

const list = (params: any = {}) => {
  return client.get("/petfood", {params})
}

const register = (data: any = {}) => {
  return client.post("/petfood", data)
}

const remove = (params: any = {}) => {
  return client.delete("/petfood", { params })
}

const fetchMaster = (params: any = {}) => {
  return client.get('/materials', { params })
}

const search = (params: any = {}) => {
  return client.get('/foods', { params })
}

const getMarkerAndProduct = (params: any = {}) => {
  return client.get('/maker_and_product', { params })
}

export default {
  list,
  search,
  remove,
  register,
  fetchMaster,
  getMarkerAndProduct,
}
