import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { Header } from "../common"
import { useApp } from "../../hooks"
import { BounceLoader } from "react-spinners"

const AppLayout = () => {
  const { isLoading } = useApp()
  
  return  (<>
    <LayoutWrapper>
      <div className="bg fixed top-0 left-0 w-screen h-screen z-[-1]"></div>
      <div className="container">
        <Header />
        <Outlet />
      </div>
      {isLoading && <>
        <LoadingOverlay>
          <BounceLoader color="#fff" size={60} />
        </LoadingOverlay>
      </>}
    </LayoutWrapper>
  </>)
}

export default AppLayout

const LayoutWrapper = styled.div`
  max-width: 768px;
  min-height: 100vh;
  margin: auto;
  overflow: auto;
  .bg {
    background: url(/bg.png) no-repeat center;
    background-size: cover;
  }
  .container {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    min-height: 100vh;
    padding: 0 0 35px;
    margin: auto;
  }
`
const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1201;
`
