import styled from "styled-components"
import { useApp, useAppDispatch, useAppSelector } from "../../hooks"
import { Input, InputPassword, RegisterStep } from "../../components/common"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { getAppStates, setRegisterCurrentStep, setRegisterData } from "../../store/appSlice"

const schema = object().shape({
  mailaddress: string().email('メール形式で入力してください')
    .required('メールアドレスを入力してください'),
  password: string().required('パスワードは必須です')
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()-_=+]).{6,}$/,
      '半角英数と記号を含む6文字以上のパスワードを入力してください'
    )
})

const Register = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { registerCurrentStep, registerData } = useAppSelector(getAppStates)

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mailaddress: '',
      password: '',
    }
  })

  useEffect(() => {
    reset({
      mailaddress: registerData.mailaddress ?? '',
      password:    registerData.password ?? '',
    })
  }, [])

  const onNext = (data: any) => {
    dispatch(setRegisterData(data))
    dispatch(setRegisterCurrentStep(Math.max(registerCurrentStep, 2)))
    navigate('/register/step2')
  }

  return (<>
    <Wrapper>
      <h1 className="title">飼い主登録<span>owner</span></h1>
      <RegisterStep index={1} />
      <h2 className="text-2xl	font-bold mt-12 mb-7">Sign UP</h2>
      <form onSubmit={handleSubmit(onNext)}>
        <Input
          label="メールアドレス"
          type="email"
          name="mailaddress"
          control={control}
        />
        <div className="text-sm my-4">
          <p>キャリアメール（docomo/au/softbank等）にメール未着が多発しております。 フリーメール（yahooやgmail等）並びにPCメールの使用を強くお勧めします。</p>
          <p>※キャリアメールの未着については、お客様自身にてキャリア側のセキュリティ設定での対応となります。</p>
        </div>
        <InputPassword
          label="パスワード"
          control={control}
        />
        <p className="font-bold text-sm mt-4 mb-14">半角英数と記号を含む6文字以上</p>
        <button
          type="submit"
          className="btn bg-green"
        >次へ</button>
        <button
          className="btn sky-1 mt-4"
          onClick={() => navigate('/login')}
        >戻る</button>
      </form>
    </Wrapper>
  </>)
}

export default Register

const Wrapper = styled.div`
  h1 {
    padding: 20px 0;
    span {
      top: 28px;
    }
  }
`



