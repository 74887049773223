import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { mixed, object } from "yup"
import { useApp, useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates, setIsEmptyPet, setNewPetData } from "../../store/appSlice"
import { useEffect, useState } from "react"
import { PetsService } from "../../api"

const schema = object().shape({
  file: mixed().defined().nullable(),
})

const Photo = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { setIsLoading } = useApp()
  const { selectedPet } = useAppSelector(getAppStates)

  const [file, setFile] = useState<File | null>(null)

  const {
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      file: null,
    }
  })

  const onChangeFile = (e: any) => {
    const file = e.target.files[0]
    
    if (file) {
      setValue('file', file)
      setFile(file)
    }
  }

  const onSubmit = async (values: any) => {
    const data = {
      id: selectedPet?.id,
      file: values.file,
    }

    try {
      setIsLoading(true)
      const { data: resData } = await PetsService.updatePhoto(data)
      const { status } = resData
      if (status == 'ok') {
        navigate('/pets/list')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper className="flex flex-col flex-1">
      <div className="flex-1 mb-5">
        <h1 className="relative text-center font-bold z-0 mt-4 mb-6">
          <span className="text-lg">{selectedPet.name ?? 'ペット'}の<br />
            ステキなお写真を<br />
            アップロードしてください。</span>
          <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">cure<br />allergies</span>
        </h1>
        <div className="text-center mb-10">
          <div className="fs-0">
            <label className="relative inline-flex align-center justify-center w-36 h-36 rounded-full bg-white overflow-hidden plus-center">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={onChangeFile}
              />
              {file ? <>
                <img src={URL.createObjectURL(file)} alt="" className="w-full h-full object-cover opacity-50" />
              </> : <>
                {selectedPet.imageUrl && <>
                  <img src={selectedPet.imageUrl ?? '/noimg.png'} alt="" className="w-full h-full object-cover opacity-50" />
                </>}
              </>}
            </label>
          </div>
          <p className="font-bold mt-1">お写真アップロード</p>
        </div>
      </div>
      <button
        className="btn bg-green"
        onClick={handleSubmit(onSubmit)}
      >更新</button>
    </Wrapper>
  </>)
}

export default Photo

const Wrapper = styled.div`
  .plus-center {
    &:before, &:after {
      content: '';
      position: absolute;
      border-radius: 999px;
      background-color: #000;
      z-index: 10;
    }
    &:before {
      width: 5px;
      height: 40px;
      top: calc(50% - 20px);
      left: calc(50% - 2.5px);
    }
    &:after {
      height: 5px;
      width: 40px;
      left: calc(50% - 20px);
      top: calc(50% - 2.5px);
    }
  }
`

