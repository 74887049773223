import { Link, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { BackLink } from "../../components/common"
import { NewsDetailModal } from "../../components/modals"
import { useEffect, useState } from "react"
import { useApp, useAppSelector } from "../../hooks"
import { NewsService } from "../../api"
import { getAppStates } from "../../store/appSlice"
import classNames from "classnames"

const News = () => {
  const navigate = useNavigate()
  const {setIsLoading} = useApp()
  const {selectedPet} = useAppSelector(getAppStates)

  const [newsList, setNewsList] = useState([])
  const [selectedNews, setSelectedNews] = useState<any>(null)

  useEffect(() => {
    const getNewsList = async () => {
      try {
        setIsLoading(true)
        const { data: resData } = await NewsService.list()
        const { status, notifications } = resData
        if (status === 'ok') {
          setNewsList(notifications)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    getNewsList()
  }, [])

  return (<>
    <Wrapper>
      <h1 className="title">お知らせ<span>INFORMATION</span></h1>
      <div className="inner">
        <ul className="news-list">
          {newsList.length ? <>
            {newsList.map((news: any, index: number) => {
              return (
                <li key={index}>
                  <a
                    className="news-item hover:cursor-pointer"
                    onClick={() => setSelectedNews(news)}
                  >{news.title}</a>
                </li>
              )
            })}
          </> : <>
            
          </>}
        </ul>
        <button
          className={classNames({
            "btn-box": true,
            "bg-sky": !!selectedPet?.foodCount,
            "bg-gray-1": !selectedPet?.foodCount
          })}
          disabled={!selectedPet?.foodCount}
          onClick={() => navigate(`/pets/${selectedPet.id}/foods`)}
        >登録ペットフード確認</button>
        <p className="fs-15 bold center txt01">最大10個までペットフードを登録できます</p>
        <button
          className={classNames({
            "btn-box btn-box--status": true,
            "bg-danger": !!selectedPet?.testCount,
            "bg-gray-1": !selectedPet?.testCount
          })}
          disabled={!selectedPet?.testCount}
          onClick={() => navigate('/results')}
        >
          <img src="/icon-search2.png" alt="" /><span>進行状況・結果確認</span>
        </button>
        <BackLink />
      </div>
    </Wrapper>
    <NewsDetailModal
      isOpen={!!selectedNews}
      onClose={() => setSelectedNews(null)}
      news={selectedNews}
    />
  </>)
}

export default News

const Wrapper = styled.div`
  h1 {
    padding: 40px 0 40px;
    margin: 0;
  }
  .inner {
    max-width: 300px;
    margin: auto;
  }
  .news-list {
    list-style: none;
    padding: 0;
    margin: 0 0 40px;
    > li {
      padding: 12px 0;
      border-bottom: 1px solid #00b1a9;
    }
  }
  .txt01 {
    margin: 10px 0 25px;
  }
  .btn-box--status {
    gap: 8px;
    img {
      width: 45px;
      height: 45px;
    }
  }
`
