import styled from "styled-components"
import { useApp, useAppSelector } from "../../hooks"
import { getAppStates } from "../../store/appSlice"
import { Link } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { TestService } from "../../api"
import dayjs from "dayjs"
import Modal from 'react-modal';

const List = () => {
  const {selectedPet} = useAppSelector(getAppStates)
  const {setIsLoading} = useApp()
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [tempDeleteItemId, setTempDeleteItemId] = useState<number | null>(null)
  const [petTests, setPetTests] = useState<any[]>([])

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const getList = async (id: number ) => {
      try {
        setIsLoading(true)
        const {data} = await TestService.list({id})
        const {status, petTests} = data
        if (status == 'ok') {
          setPetTests(petTests)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    if (selectedPet?.id)
      getList(selectedPet.id)
  }, [])

  const onDeletePetTest = async (event: any, item: any) => {
    event.preventDefault()
    setTempDeleteItemId(item.id)
    openModal()
  }

  const onDeleteConfirm = async () => {
    closeModal()
    setIsLoading(true)
    const { data } = await TestService.remove({id: tempDeleteItemId})
    if (data.status == 'ok') {
        setPetTests(petTests.filter((x) => x.id !== tempDeleteItemId))
    }
    setIsLoading(false)
    setTempDeleteItemId(null)
  }

  return (
    <Wrapper>
      <div className="flex-1">
        <h1 className="relative text-center min-h-16 font-bold pt-2 z-0 mt-3 mb-6">
          <span className="text-lg">{selectedPet?.name}さんの<br />検体提出履歴</span>
          <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">FOOD<br />research</span>
        </h1>
        <div className="border-t pt-3 mb-5">
          <h2 className="d-flex justify-center gap-3">
            <i><img src="/icon-search2.png" alt="" className="w-6 mt-1" /></i><span className="text-xl">検査結果一覧</span>
          </h2>
        </div>
        <div className="d-flex flex-col gap-3">
          {petTests.map((item, index) => (
            <Link
              key={index}
              to={item.inTesting == 0 ? `/test/${item.id}/send` : `/results/${item.id}`}
              className="flex items-center w-full px-4 py-1.5 text-black font-bold bg-[#f8b551]"
            >
              {dayjs(item.testDate).format('YYYY年MM月DD日')}｜検査結果表示
              <i className="ml-auto" onClick={(event) => onDeletePetTest(event, item)}><img src="/delete.png" alt="remove" className="w-5" /></i>
            </Link>
          ))}
        </div>
      </div>
      <Link
        to={`/pets/${selectedPet?.id}/info`}
        className="block w-full text-center leading-10 btn bg-green"
      >マイページ</Link>

      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="削除確認"
          style={{
            overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
            content: { color: 'black', width: '300px', margin: 'auto', padding: '20px', borderRadius: '8px', height: '160px' }
          }}
      >
        <h2>削除確認</h2>
        <p>本当にこの検査結果を削除してもよろしいですか？</p>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
          <button onClick={closeModal} style={{ padding: '8px', cursor: 'pointer' }}>キャンセル</button>
          <button onClick={onDeleteConfirm} style={{ backgroundColor: 'red', color: 'white', padding: '8px', border: 'none', cursor: 'pointer' }}>
            削除
          </button>
        </div>
      </Modal>
    </Wrapper>
  )
}

export default List

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`
