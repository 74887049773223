import styled from "styled-components"
import { BtnConfirmSearch } from "../../components/common/buttons"
import { useNavigate } from "react-router-dom"
import classNames from "classnames"

const Status = () => {
  const navigate = useNavigate()

  return (<>
    <Wrapper>
      <h1 className="relative text-center min-h-16 font-bold pt-2 z-0 mt-3 mb-6">
        <span className="text-lg">●●●さんの<br />新規検査御申込受付が<br />完了しました。</span>
        <span className="inline-block absolute top-0 left-0 w-full futurabt text-3xl leading-8 text-center sky-2 tracking-widest uppercase z-[-1]">FOOD<br />research</span>
      </h1>
      <h2 className="text-lg border-t-2 border-white text-center pt-3 mb-7">現在の進行状況です</h2>
      <div>
        <label
          className={classNames({
            'label relative block text-center font-bold py-2 mb-8': true,
            'bg-sky text-black': true,
          })}
        >新規受付完了</label>
        <label
          className={classNames({
            'label relative block text-center font-bold py-2 mb-8': true,
            'bg-sky text-black': true,
          })}
        >検体（被毛）郵送完了</label>
        <label
          className={classNames({
            'label relative block text-center font-bold py-2 mb-8 bg-gray-1': true,
          })}
        >検体（被毛）USラボ手配完了</label>
        <label
          className={classNames({
            'label relative block text-center font-bold py-2 mb-8 bg-gray-1': true,
          })}
        >USラボによる検査実施中</label>
      </div>
      <div className="w-72 m-auto mb-11">
        <BtnConfirmSearch
          label="検査完了結果確認"
          disabled
        />
      </div>
      <button
        className="btn bg-green"
        onClick={() => navigate('/mypage')}
      >マイページ</button>
    </Wrapper>
  </>)
}

export default Status

const Wrapper = styled.div`
  .label {
    &:after {
      content: '▼';
      position: absolute;
      left: 50%;
      top: calc(100% + 5px);
      font-size: 14px;
      color: #bcbcbc;
      transform: translateX(-50%);
    }
    &.bg-sky:after {
      color: #00b8e7;
    }
  }
`
