import classNames from "classnames";
import React from "react";
import styled from "styled-components"

interface Props {
  index: number
}

const RegisterStep = ({
  index
}: Props) => {
  const steps = [
    {index: '❶', label: '基本情報①'},
    {index: '❷', label: '基本情報②'},
    {index: '➌', label: '住所'},
    {index: '➍', label: '入力確認'},
  ];

  return (<>
    <Wrapper className="flex justify-center align-center">
      {steps.map((el:any, i: number) => {
        return (<React.Fragment key={i}>
          <label
            className={classNames({
              "relative text-center": true,
              "opacity-40": index < i + 1
            })}>
            <p className="index text-4xl">
              {(index > i + 1) ? <>
                <span className="passed"></span>
              </> : <>{el.index}</>}
            </p>
            <p className="name text-xs absolute">{el.label}</p>
          </label>
          {(i !== steps.length - 1) && <>
            <span className={classNames({
              "opacity-40": index < i + 1
            })}></span>
          </>}
        </React.Fragment>)
      })}
    </Wrapper>
  </>)
}

export default RegisterStep

const Wrapper = styled.div`
  .index {
    font-family: '游ゴシック', 'Yu Gothic', '游ゴシック体', 'YuGothic Medium', "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ Pro W6", "メイリオ","Meiryo",Arial, sans-serif;
  }
  .name {
    top: calc(100%);
    left: 50%;
    white-space: nowrap;
    transform: translateX(-50%);
  }
  span {
    width: 40px;
    height: 1px;
    margin: 0 8px;
    background-color: #fff;
  }
  .passed {
    display: inline-block;
    width: 36px;
    height: 36px;
    background: url(/icon-pass.png) no-repeat center;
    background-size: contain;
  }
`
