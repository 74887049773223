import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link } from "react-router-dom"
import { useAppDispatch } from "../../hooks"
import { useEffect } from "react"
import { clearNewPetData } from "../../store/appSlice"

const Question1 = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(clearNewPetData())
  }, [])

  return (<>
    <Wrapper className="relative">
      <QuestionHeader
        index="1"
      >
        <div className="font-bold">
          <p className="text-sm">簡単な質問に答えて</p>
          <p className="leading-5">あなたの愛犬・愛猫について<br />教えてください</p>
        </div>
      </QuestionHeader>
      <Link
        to={'/pets/new/ques2'}
        className="block w-full text-xl text-center rounded-full font-bold bg-green-1 py-3"
      >ご登録スタート</Link>
      <img
        className="absolute w-60 right-0"
        style={{
          top: 'calc(100% + 50px)',
        }}
        src="/img04.png"
        alt=""
      />
    </Wrapper>
  </>)
}

export default Question1

const Wrapper = styled.div`
  
`

