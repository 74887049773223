import { Link } from "react-router-dom"
import styled from "styled-components"
import { DateSelector, Input, InputTel, InputZipcode, RadioGroup, Select } from "../../components/common"
import { GENDERS, PREFECTURES } from "../../helpers/enums"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { useApp } from "../../hooks"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { ProfilesService } from "../../api"

const schema = object().shape({
  name: string().required('お名前を入力してください'),
  sex: string().required('性別を選択してください'),
  birthDate: string().required('生年月日を選択してください'),
  postCode: string().required('郵便番号を入力してください')
    .matches(/^\d{7}$/, '郵便番号の形式が正しくありません'),
  postCodeLeft: string().required('郵便番号を入力してください')
    .matches(/^\d{3}$/, '郵便番号の形式が正しくありません'),
  postCodeRight: string().required('郵便番号を入力してください')
    .matches(/^\d{4}$/, '郵便番号の形式が正しくありません'),
  prefecture: string().required('都道府県を選択してください'),
  municipalities: string().required('市区町村を入力してください'),
  houseNumber: string().required('建物名を入力してください'),
  phoneNumber: string().required('電話番号を入力してください')
    .matches(/^\d{11}$/, '電話番号の形式が正しくありません'),
})

const Owner = () => {
  const { user, setUser, setIsLoading } = useApp()

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  useEffect(() => {
    const postCode = (user?.postCode ?? '').replace('-', '')
    reset({
      name: user?.name ?? '',
      sex: user?.sex ?? '',
      birthDate: user?.birthDate ?? '',
      postCode: postCode,
      postCodeLeft: postCode.slice(0, 3) ?? '',
      postCodeRight: postCode.slice(3) ?? '',
      prefecture: user?.prefectureId ?? '',
      municipalities: user?.municipalities ?? '',
      houseNumber: user?.houseNumber ?? '',
      phoneNumber: user?.phoneNumber ?? '',
    })
  }, [user])

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true)
      const { data } = await ProfilesService.update(values)
      if (data.status === 'ok') {
        setUser(data.account)
        toast.success('登録情報を更新しました。')
      } else {
        toast.error('登録情報の更新に失敗しました。')
      }
    } catch (err) {
      console.log(err)
      toast.error('登録情報の更新に失敗しました。')
    } finally {
      setIsLoading(false)
    }
  }

  return (<>
    <Wrapper>
      <h1 className="title">飼い主登録情報<br />編集・変更</h1>
      <div>
        <Input
          label={'お名前'}
          name="name"
          control={control}
        />
        <RadioGroup
          label="性別"
          name="sex"
          control={control}
          options={GENDERS}
        />
        <DateSelector
          label="生年月日"
          name="birthDate"
          control={control}
          minYear={1900}
        />
        <InputZipcode
          label="郵便番号"
          name="postCode"
          control={control}
        />
        <Select
          label="都道府県"
          name={'prefecture'}
          control={control}
          options={PREFECTURES}
          controlClassName="!w-28"
        />
        <Input
          label={'市区町村'}
          name="municipalities"
          control={control}
        />
        <Input
          label={'番地以降'}
          name="houseNumber"
          control={control}
        />
        <InputTel
          label={'電話番号'}
          name="phoneNumber"
          control={control}
        />
        <button
          className="btn bg-green"
          onClick={handleSubmit(onSubmit)}
        >更新</button>
      </div>
    </Wrapper>
  </>)
}

export default Owner

const Wrapper = styled.div`
  .btn {
    margin-top: 20px;
  }
`
