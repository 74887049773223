import { useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { MenuModal } from "../modals"
import { useApp, useAppSelector } from "../../hooks"
import { getAppStates } from "../../store/appSlice"

interface Props {
  hiddenMenu?: boolean
}

const Header = ({
  hiddenMenu = false
}: Props) => {
  const { user } = useApp()
  const { selectedPet, isEmptyPet, isAuthenticated } = useAppSelector(getAppStates)
  const [isOpen, setIsOpen] = useState(false)

  return (<>
    <HeaderWrapper>
      <Link to={!isAuthenticated ? '/login' : (isEmptyPet ? '/pets/new/ques1' : '/pets/list')} className="logo">
        <img src="/logo.png" alt="ファイブストランドジャパン" />
      </Link>
      {(user && !hiddenMenu) && <>
        <div className="nav-right">
          {selectedPet && <>
            <div className="avatar">
              <Link to={`/pets/${selectedPet.id}/info`}>
                <img src={selectedPet.imageUrl ?? '/noimg.png'} alt="" className="rounded-full" />
              </Link>
            </div>
          </>}
          <div className="fs-0">
            <div className="btn-hamburger"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <MenuModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      </>}
    </HeaderWrapper>
  </>)
}

export default Header

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  .logo {
    width: 170px;
  }
  .nav-right {
    display: flex;
    align-items: center;
    gap: 20px;
    .avatar img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
    .btn-hamburger {
      display: inline-block;
      width: 40px;
      span {
        position: relative;
        display: block;
        height: 3px;
        background-color: #fff;
        &:not(:first-child) {
          margin-top: 9px;
        }
      }
    }
  }
`