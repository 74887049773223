import styled from "styled-components"
import { QuestionHeader } from "./components"
import { Link, useNavigate } from "react-router-dom"
import { RadioBoxGroup, Textarea } from "../../components/common"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { object, string } from "yup"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { getAppStates, setNewPetData } from "../../store/appSlice"
import { YN } from "../../helpers/enums"
import { useEffect } from "react"

const schema = object().shape({
  isMedicineWorking: string().required('服用中の薬を選択してください'),
  medicineComment: string().defined().nullable(),
})

const Question10 = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { newPetData } = useAppSelector(getAppStates)

  const {
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      isMedicineWorking: '',
      medicineComment: '',
    }
  })

  useEffect(() => {
    reset({
      isMedicineWorking: newPetData.isMedicineWorking,
      medicineComment:   newPetData.medicineComment
    })
  }, [])

  const onNext = (values: any) => {
    dispatch(setNewPetData(values))
    navigate('/pets/new/ques11')
  }

  return (<>
    <Wrapper className="flex flex-col flex-1">
      <div className="flex-1 mb-5">
        <QuestionHeader
          index="10"
        >
          <div className="font-bold">
            <p className="leading-5">{newPetData.name ?? 'ペット'}は<br />現在服用中の薬はありますか？</p>
          </div>
        </QuestionHeader>
        <div className="mt-6 mb-10">
          <RadioBoxGroup
            name="isMedicineWorking"
            control={control}
            options={YN}
            className={'mb-12'}
          />
          <Textarea
            label="服用中の薬を教えてください。"
            name="medicineComment"
            control={control}
            rows={6}
          />
        </div>
      </div>
      <button
        className="btn bg-green"
        onClick={handleSubmit(onNext)}
      >次へ</button>
      <button className="btn sky-1 mt-4"
        onClick={() => navigate('/pets/new/ques9')}
      >戻る</button>
    </Wrapper>
  </>)
}

export default Question10

const Wrapper = styled.div`
  
`

