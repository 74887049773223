import { Navigate, useLocation } from "react-router-dom";
import { useApp, useAppSelector } from "../../hooks";
import { getAppStates } from "../../store/appSlice";

type PrivateRouteProps = {
  children: JSX.Element;
  isSelectedPet?: boolean;
};

const Middleware = ({
  children,
  isSelectedPet = false
}: PrivateRouteProps) => {
  const  { selectedPet } = useAppSelector(getAppStates)

  if (isSelectedPet && !selectedPet) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/pets/list" replace />;
  }

  return children;
};

export default Middleware;
