import classNames from "classnames"
import { useNavigate } from "react-router-dom"

interface Props {
  disabled?: boolean,
  label?: string,
  onClick?: () => void,
}

const BtnConfirmSearch = ({
  disabled = false,
  label = '進行状況・結果確認',
  onClick,
}: Props) => {
  const navigate = useNavigate()

  const onConfirm = () => {
    if (disabled) return
    if (onClick) {
      onClick()
      return
    }
    navigate('/results')
  }

  return (<>
    <button
      className={classNames({
        "flex align-center max-w-80 w-full h-16 gap-2 py-3 px-5 rounded-xl m-auto bg-danger": true,
        'bg-gray-1': disabled
      })}
      onClick={onConfirm}
      disabled={disabled}
    >
      <figure>
        <img src="/icon-search2.png" alt="" className="h-11" />
      </figure>
      <div className="text-left">
        <p className="text-2xl font-bold leading-none">{label}</p>
      </div>
    </button>
  </>)
}

export default BtnConfirmSearch
