import classNames from "classnames"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { ContactFooter } from "../common"
import { useApp, useAppDispatch } from "../../hooks"
import { resetState } from "../../store/appSlice"
import Cookies from "js-cookie"

interface Props {
  isOpen: boolean,
  onClose: () => void,
  news?: any,
}

const NewsDetailModal = ({
  isOpen,
  onClose,
  news,
}: Props) => {
  const dispatch = useAppDispatch()
  const { setUser } = useApp()

  const logout = () => {
    onClose()
    setUser(null)
    dispatch(resetState())
    Cookies.remove('user_access_token')
  }

  return (<>
    <Wrapper
      className={classNames({
        'menu-overlay': true,
        'show': isOpen
      })}
    >
      <div className="menu-body">
        <button
          className="btn-close"
          onClick={onClose}
        >
          <img src="/icon-close.png" alt="" />
        </button>
        <hr />
        <h2>{news?.title}</h2>
        <hr />
        <div className="news-body whitespace-pre-line" dangerouslySetInnerHTML={{ __html: news?.content }}></div>
        <hr />
        <div className="footer">
          <ContactFooter />
          <button
            className="btn-logout"
            onClick={logout}
          >ログアウト</button>
        </div>
      </div>
    </Wrapper>  
  </>)
}
export default NewsDetailModal

const Wrapper = styled.div`
  padding: 35px 20px 20px;
  background-color: rgba(255, 255, 255, 0);
  .menu-body {
    max-height: calc(100vh - 80px);
    background-color: rgba(227, 227, 227, .85);
    overflow: auto;
  }
  .footer {
    padding: 15px 15px 0 15px;
  }
  h2 {
    font-size: 16px;
    padding: 8px 15px;
    color: #000;
  }
  .news-body {
    font-size: 14px;
    color: #000;
    padding: 12px 15px;
    a {
      text-decoration: underline;
    }
  }
`
