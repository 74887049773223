export const valueToLabel = (value: any, dictionary: Array<any>, key = 'value', returnKey = 'label') => {
  if (value === undefined || value === null) return ''

  const obj = dictionary.find(el => el[key] == value)
  if (!obj) return value

  return obj[returnKey]
}

export const leadingZero = (num: any, size = 2) => {
  return ('0' + num).slice(-1 * size)
}
