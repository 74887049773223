import client from "./client"

const list = ()  => {
  return client.get("/pets")
}

const get = (params: any = {}) => {
  return client.get(`/pet`, { params })
}

const getFoodCount = (params: any = {}) => {
  return client.get(`/petfood_count`, { params })
}

const update = (data: any) => {
  return client.put("/pet", data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

const breedList = () => {
  return client.get("/pet_options")
}

const create = (data: any) => {
  return client.post("/pet", data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

const updatePhoto = (data: any) => {
  return client.put("/pet_image", data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export default {
  get,
  list,
  create,
  update,
  breedList,
  updatePhoto,
  getFoodCount,
}
