import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { useAppSelector } from "../../hooks"
import { getAppStates } from "../../store/appSlice"
import { BtnNewFood } from "../../components/common/buttons"
import { useEffect, useMemo, useState } from "react"
import { PetsService } from "../../api"
import classNames from "classnames"
import { LinkGroup } from "../../components/common"

const FoodSupport1 = () => {
  const {id: petId} = useParams()
  const navigate = useNavigate()
  const {selectedPet} = useAppSelector(getAppStates)
  const [petfoodCount, setPetfoodCount] = useState(0)

  useEffect(() => {
    const getFoodCount = async (id: string) => {
      const {data} = await PetsService.getFoodCount({id})
      const {status, petfoodCount} = data
      if (status == 'ok') {
        setPetfoodCount(petfoodCount)
      }
    }

    if (petId) getFoodCount(petId)
  }, [])

  const linkDisable = useMemo(() => {
    return petfoodCount >= 10
  }, [petfoodCount])

  return (<>
    <Wrapper>
      <h1>検査結果から<br />{selectedPet?.name}さんの<br />不耐性にあった食事を<br />探しましょう</h1>
      <button
        className={classNames({
          'max-w-80 btn-box bg-blue mb-25': true,
          'opacity-70': linkDisable,
        })}
        onClick={() => navigate(`/pets/${petId}/food-support1`)}
        disabled={linkDisable}
      >メーカー・商品名から探す</button>
      <button
        className={classNames({
          'max-w-80 btn-box bg-blue': true,
          'opacity-70': linkDisable,
        })}
        onClick={() => navigate(`/pets/${petId}/food-support2`)}
        disabled={linkDisable}
      >排除したい成分から探す</button>
      <p className="txt01">登録は検査前、検査後にいづれも可能です。<br />登録したペットフードの原材料に<br />検査結果のレベル（色）が反映されます。</p>
      <BtnNewFood
        disabled={!selectedPet?.testCount}
      />
      <p className="txt02">最大10個までペットフードを登録できます</p>
      <LinkGroup petId={petId} />
    </Wrapper>
  </>)
}

export default FoodSupport1

const Wrapper = styled.div`
  h1 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0 40px;
    background: url(/txt01.png) no-repeat center;
    background-size: auto 70px;
  }
  .txt01 {
    text-align: center;
    margin: 20px 0 60px;
  }
  .txt02 {
    text-align: center;
    font-weight: bold;
    margin-top: 12px;
  }
`
